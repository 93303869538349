import { makeStyles, alpha } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    inputRoot: {
      color: theme.colors.primaryBackgroundImageTextColor
    },
    inputUnderline: {
      "&:before": {
        borderBottomColor: theme.colors.primaryBackgroundImageTextColor
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottomColor: theme.colors.primaryBackgroundImageTextColor
      },
      "&:after": {
        borderBottomColor: theme.colors.primaryBackgroundImageTextColor
      }
    },
    inputLabelRoot: {
      color: alpha(theme.colors.primaryBackgroundImageTextColor, 0.7),
      "&.Mui-focused:not(.Mui-error)": {
        color: theme.colors.primaryBackgroundImageTextColor
      }
    }
  }),
  { name: "CTXTextField" }
);

export default function CTXTextField(props) {
  const classes = useStyles();

  let { color, InputProps, InputLabelProps, ...other } = props;

  if (color === "primaryBackgroundImageText") {
    InputProps = {
      classes: {
        root: classes.inputRoot,
        underline: classes.inputUnderline
      }
    };
    InputLabelProps = {
      classes: {
        root: classes.inputLabelRoot
      }
    };
  }

  return (
    <TextField
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      {...other}
    />
  );
}
