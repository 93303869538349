import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import { Field, reduxForm } from "redux-form";
import {
  loginUser,
  clearLoginError,
  setIsLoginFormSubmitting,
  setVideoInput,
  setWebCamOn,
  setWebCamOff,
  setAudioOn,
  setAudioOff,
  showSettingsMenu,
  setCMLoggedIn,
  setConnectPopperExpansion
} from "../../actions";
import AppLogo from "../Icons/AppLogo";
import { generateFieldId, generateMessageId, validate } from "./utils";
import { LoginErrors } from "../../errors";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import {
  FormControlLabel,
  Checkbox,
  Switch,
  IconButton
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as cookiesManager from "../mediasoup/cookiesManager";
import { isWebRTCAudioEnabled, isVideoEnabled, isCM } from "../../utils";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl, modifyFilter, resetPreviewStream } from "../../utils";
import classNames from "classnames";
import Logger from "../../Logger";
import { withRoomContext } from "../mediasoup/RoomContext";
import CTXTextField from "../reusable/CTXTextField";

const logger = new Logger("LoginForm");

const formName = "loginForm";
const REMEMBER_ME = "rememberme";
const REMEMBER_USERNAME = "username";
const REMEMBER_USER_DEFINED1 = "company";
const REMEMBER_USER_DEFINED2 = "title";
const REMEMBER_USER_DEFINED3 = "email";
const REMEMBER_USER_DEFINED4 = "location";
export const REMEMBER_CALL_ME = "callme";

export const loginFormFields = [
  {
    name: "passcode",
    id: generateFieldId(formName, "passcode"),
    messageId: generateMessageId(formName, "Passcode"),
    required: true,
    digitsValidator: /^\d+$/,
    minDigits: window.CtxAppConfigurations.customer === "TCAD" ? 6 : null,
    maxLength:
      window.CtxAppConfigurations.customer === "TCAD"
        ? 6
        : window.CtxAppConfigurations.conferencePasscodeMaxLength
  },
  {
    name: "propertyID",
    id: generateFieldId(formName, "propertyID"),
    messageId: generateMessageId(formName, "PropertyID"),
    required: true,
    digitsValidator: /^\d+$/,
    minDigits: 6,
    maxLength: 6
  },
  {
    name: "agentID",
    id: generateFieldId(formName, "agentID"),
    messageId: generateMessageId(formName, "AgentID"),
    required: true,
    digitsValidator: /^\d+$/,
    minDigits: 4,
    maxLength: 4
  },
  {
    name: "pin",
    id: generateFieldId(formName, "pin"),
    messageId: generateMessageId(formName, "PIN"),
    required: true,
    digitsValidator: /^\d+$/,
    maxLength: 12
  },
  // A second PIN field is needed in order to avoid refactoring the code to use
  // field-level validation.
  {
    name: "pinOptional",
    id: generateFieldId(formName, "pinOptional"),
    messageId: generateMessageId(formName, "PINOptional"),
    required: false,
    digitsValidator: /^\d+$/,
    maxLength: 12
  },
  {
    name: "username",
    id: generateFieldId(formName, "username"),
    messageId: generateMessageId(formName, "Name"),
    required: true,
    maxLength: window.CtxAppConfigurations.partyNameMaxLength
  },
  {
    name: "userDefined",
    id: generateFieldId(formName, "userDefined"),
    messageId: generateMessageId(formName, "UserDefined"),
    maxLength: window.CtxAppConfigurations.partyUserDefinedMaxLength
  },
  {
    name: "userDefined2",
    id: generateFieldId(formName, "userDefined2"),
    messageId: generateMessageId(formName, "UserDefined2"),
    maxLength: window.CtxAppConfigurations.partyUserDefinedMaxLength
  },
  {
    name: "userDefined3",
    id: generateFieldId(formName, "userDefined3"),
    messageId: generateMessageId(formName, "UserDefined3"),
    required: true,
    emailValidator: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    maxLength: window.CtxAppConfigurations.partyUserDefinedMaxLength
  },
  {
    name: "userDefined4",
    id: generateFieldId(formName, "userDefined4"),
    messageId: generateMessageId(formName, "UserDefined4"),
    maxLength: window.CtxAppConfigurations.partyUserDefinedMaxLength
  },
  {
    name: "recordingKey",
    id: generateFieldId(formName, "recordingKey"),
    messageId: generateMessageId(formName, "RecordingKey"),
    required: true,
    autoComplete: "off"
  }
].filter(ele => {
  return ele !== undefined;
});

const styles = theme => ({
  mainDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "auto"
    }
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.colors.primaryBackgroundImageTextColor,
    alignSelf: "center"
  },
  requiredLabelContainer: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  loginButton: {
    margin: "5px"
  },
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "20px",
    color: theme.palette.error.main,
    marginTop: "5px",
    marginBottom: "5px"
  },
  logo: {
    maxHeight: "200px",
    width: "330px",
    objectFit: "contain",
    marginBottom: "20px"
  },
  form: {
    width: "370px"
  },
  videoForm: {
    [theme.breakpoints.up("md")]: {
      marginRight: "20px"
    }
  },
  fields: {
    height: "70px"
  },
  fieldMargin: {
    margin: "auto"
  },
  rememberMeContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  formCheckbox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  formControlLabel: {
    color: theme.colors.primaryBackgroundImageTextColor
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.primaryMainTextColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  },
  video: {
    width: "320px",
    height: "180px",
    borderColor: theme.colors.primaryMainColor,
    borderStyle: "solid"
  },
  deviceSettingsContainer: {
    width: "320px",
    display: "flex",
    justifyContent: "space-between"
  },
  spaceAround: {
    justifyContent: "space-around"
  },
  settingIconButton: {
    width: "48px"
  },
  audioSettingIconButton: {
    width: "48px",
    marginLeft: "-12px",
    marginRight: "-12px"
  },
  videoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20px"
  }
});

class LoginForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      persistentHostLogin: false,
      rememberMe: JSON.parse(localStorage.getItem(REMEMBER_ME)),
      videoInputDeviceId:
        cookiesManager.getVideoInput() != null &&
        cookiesManager.getVideoInput().videoInputDeviceId !== undefined
          ? cookiesManager.getVideoInput().videoInputDeviceId
          : this.props.session.videoInput
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      const initialValues = {
        passcode: this.props.passcode,
        username: this.props.username
          ? this.props.username
          : localStorage.getItem(REMEMBER_USERNAME),
        userDefined: this.props.userDefined
          ? this.props.userDefined
          : localStorage.getItem(REMEMBER_USER_DEFINED1),
        userDefined2: this.props.userDefined2
          ? this.props.userDefined2
          : localStorage.getItem(REMEMBER_USER_DEFINED2),
        userDefined3: this.props.userDefined3
          ? this.props.userDefined3
          : localStorage.getItem(REMEMBER_USER_DEFINED3),
        userDefined4: this.props.userDefined4
          ? this.props.userDefined4
          : localStorage.getItem(REMEMBER_USER_DEFINED4)
      };

      this.props.initialize(initialValues);

      const webrtcVideoEnabled = isVideoEnabled(this.props.session.videoConfig);
      if (webrtcVideoEnabled) {
        try {
          let stream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: this.props.session.videoInput,
              width: 1280,
              height: 720
            }
          });
          stream.getTracks().forEach(track => {
            track.stop();
          });
        } catch (error) {
          logger.error("Error: %o", error);
        }

        await this.getDevices();

        if (this.props.session.webcamEnabled) {
          this.setPreviewStream(this.state.videoInputDeviceId);
        } else {
          resetPreviewStream(this, "videoElement");
        }
      }
    }
  }

  getDevices = () => {
    this.enumeratorPromise = navigator.mediaDevices.enumerateDevices();
    this.enumeratorPromise
      .then(this.listVideoInputDevices)
      .catch(this.errorCallbackOnListDevices);
  };

  listVideoInputDevices = devices => {
    let videoInputDevices = [];
    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];
      if (device.kind === "videoinput") {
        videoInputDevices.push(device);
      }
    }

    let newDeviceId = this.state.videoInputDeviceId;
    let propsDeviceId = this.props.session.videoInput;
    if (propsDeviceId === "default" || propsDeviceId === "") {
      if (
        cookiesManager.getVideoInput() == null ||
        cookiesManager.getVideoInput().videoInputDeviceId === "default" ||
        cookiesManager.getVideoInput().videoInputDeviceId === ""
      ) {
        newDeviceId = videoInputDevices[0].deviceId;
        logger.debug("Updating cookie & session videoInput: %s", newDeviceId);
        this.props.setVideoInput(newDeviceId);
        cookiesManager.setVideoInput({
          videoInputDeviceId: newDeviceId
        });
        this.setState({
          videoInputDeviceId: newDeviceId
        });
      } else {
        newDeviceId = cookiesManager.getVideoInput().videoInputDeviceId;
        logger.debug("Updating session videoInput with: %s", newDeviceId);
        this.props.setVideoInput(newDeviceId);
        this.setState({
          videoInputDeviceId: newDeviceId
        });
      }
    }
  };

  errorCallbackOnListDevices = error => {
    logger.error("Error: %o", error);
    cookiesManager.setVideoInput({ videoInputDeviceId: "default" });
  };

  componentDidUpdate(prevProps) {
    if (this._isMounted) {
      const { webcamEnabled, videoInput, videoConfig, blur } =
        this.props.session;
      if (
        prevProps.session.videoInput !== videoInput ||
        prevProps.session.webcamEnabled !== webcamEnabled
      ) {
        if (prevProps.session.videoInput !== videoInput) {
          this.setState({ videoInputDeviceId: videoInput });
        }

        if (prevProps.session.webcamEnabled !== webcamEnabled) {
          this.setState({ webcamEnabled });
        }

        if (webcamEnabled && isVideoEnabled(videoConfig)) {
          this.setPreviewStream(videoInput);
        } else {
          resetPreviewStream(this, "videoElement");
        }
      } else if (
        prevProps.session.blur !== blur &&
        webcamEnabled &&
        isVideoEnabled(videoConfig)
      ) {
        modifyFilter(this, blur, "videoElement");
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    resetPreviewStream(this, "videoElement");
  }

  setPreviewStream = deviceId => {
    logger.debug("setPreviewStream with deviceId: %s", deviceId);

    if (deviceId !== "default" && deviceId !== "") {
      var videoElement = document.getElementById("videoElement");
      if (videoElement) {
        if (this.previewPending) {
          return;
        } else {
          this.previewPending = true;
        }

        if (this.stream) {
          // Stop the unfiltered track
          this.stream.getVideoTracks().forEach(track => {
            track.stop();
          });
          this.stream = null;
        }

        navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: { exact: deviceId },
              width: 1280,
              height: 720
            }
          })
          .then(stream => {
            if (this._isMounted) {
              this.stream = stream;
              modifyFilter(this, this.props.session.blur, "videoElement");
            }
            this.previewPending = false;
          })
          .catch(error => {
            logger.error("Error: %o", error);
            this.previewPending = false;
          });
      }
    }
  };

  handleWebcamOn = () => {
    if (cookiesManager.getVideoInput() == null) {
      cookiesManager.setVideoInput({
        videoInputDeviceId: this.state.videoInputDeviceId
      });
    }
    this.props.setWebCamOn();
  };

  handleCheckBoxChange = eventObj => {
    this.setState({
      ...this.state,
      rememberMe: eventObj.target.checked
    });
  };

  handleAudioSwitchChange = eventObj => {
    if (eventObj.target.checked) {
      this.props.setAudioOn();
    } else {
      this.props.setAudioOff();
    }
  };

  handleVideoSwitchChange = eventObj => {
    if (eventObj.target.checked) {
      this.handleWebcamOn();
    } else {
      this.props.setWebCamOff();
    }
  };

  saveRememberInfo = formValues => {
    localStorage.setItem(REMEMBER_ME, true);
    if (formValues.username) {
      localStorage.setItem(REMEMBER_USERNAME, formValues.username);
    }
    if (formValues.userDefined) {
      localStorage.setItem(REMEMBER_USER_DEFINED1, formValues.userDefined);
    }
    if (formValues.userDefined2) {
      localStorage.setItem(REMEMBER_USER_DEFINED2, formValues.userDefined2);
    }
    if (formValues.userDefined3) {
      localStorage.setItem(REMEMBER_USER_DEFINED3, formValues.userDefined3);
    }
    if (formValues.userDefined4) {
      localStorage.setItem(REMEMBER_USER_DEFINED4, formValues.userDefined4);
    }
  };

  deleteRememberInfo = () => {
    localStorage.removeItem(REMEMBER_ME);
    localStorage.removeItem(REMEMBER_USERNAME);
    localStorage.removeItem(REMEMBER_USER_DEFINED1);
    localStorage.removeItem(REMEMBER_USER_DEFINED2);
    localStorage.removeItem(REMEMBER_USER_DEFINED3);
    localStorage.removeItem(REMEMBER_USER_DEFINED4);
    localStorage.removeItem(REMEMBER_CALL_ME);
  };

  handleContinueAsHost = (eventObj, fieldNames) => {
    eventObj.preventDefault();

    let errors = validate(loginFormFields, this.props.form.loginForm.values);

    for (const fieldName in errors) {
      if (fieldNames.includes(fieldName)) {
        return;
      }
    }

    this.props.change("passcode", "");
    this.setState({
      persistentHostLogin: true
    });

    this.props.clearLoginError();
  };

  handleBackButtonClick = eventObj => {
    eventObj.preventDefault();
    this.props.change("passcode", this.props.passcode);
    this.setState({
      persistentHostLogin: false
    });
  };

  onSubmit = values => {
    const formValues = { ...values };
    if (formValues.pinOptional) {
      formValues.pin = formValues.pinOptional;
      delete formValues.pinOptional;
    }
    if (this.props.recorder) {
      formValues.username = "Recorder";
    }

    if (this.state.rememberMe) {
      //save login info to local storage
      this.saveRememberInfo(formValues);
    } else {
      //delete login info from local storage
      this.deleteRememberInfo();
    }

    const { login } = this.props;
    this.props.setIsLoginFormSubmitting(true);
    login(
      formValues,
      this.props.session.persistentSpaceName,
      this.props.onSuccess
    );
  };

  handleContinueButtonClick = () => {
    if (isCM()) {
      cookiesManager.setDevices({
        webcamEnabled: this.props.session.webcamEnabled
      });
      if (this.props.session.mergedCallState === "disconnected") {
        this.props.setConnectPopperExpansion(true);
      } else if (this.props.session.mergedCallState === "connected") {
        this.props.setAudioOff();
        this.props.setConnectPopperExpansion(false);
      }
      this.props.setCMLoggedIn(1);
    }
  };

  renderError = error => {
    if (!error) {
      return null;
    }

    switch (error) {
      case LoginErrors.DUPLICATE_PIN:
        return <LocalizedText value="duplicatePIN" />;
      case LoginErrors.NO_PIN:
        return <LocalizedText value="conferenceRequiresPIN" />;
      case LoginErrors.CONFERENCE_INACTIVE:
        return <LocalizedText value="conferenceInactive" />;
      case LoginErrors.SESSION_INVALID:
        return <LocalizedText value="sessionInvalid" />;
      case LoginErrors.INVALID_PIN:
        return <LocalizedText value="pinInvalid" />;
      default:
        return <LocalizedText value="loginUnknown" />;
    }
  };

  renderTextField = ({
    input,
    label,
    meta: { touched, error, visited, active },
    ...custom
  }) => {
    const { field } = custom;
    const componentFields =
      error === "loginFormErrorMessageRequired" ||
      error === "loginFormErrorMessageInvalidEmailAddress" ||
      error === "loginFormErrorMessageInvalid" ||
      error === "loginFormErrorMessageNotEnoughDigits"
        ? {
            error: visited && !active && error !== undefined,
            required: field.required,
            helperText:
              visited && !active && this.props.languages.translations[error]
          }
        : {
            error: (touched || visited) && error !== undefined,
            required: field.required,
            helperText: visited && this.props.languages.translations[error]
          };

    const localizedLabel = (
      <FormattedMessage id={field.messageId} defaultMessage="???" />
    );

    const { classes } = this.props;

    return (
      <Fragment>
        <CTXTextField
          id={field.id}
          className={classes.fields}
          label={localizedLabel}
          fullWidth
          {...componentFields}
          {...input}
          inputProps={{
            maxLength: field.maxLength
          }}
          autoComplete={field.autoComplete}
          color="primaryBackgroundImageText"
        />
      </Fragment>
    );
  };

  renderDeviceSettings = () => {
    const { classes, intl, session } = this.props;
    const webrtcAudioEnabled = isWebRTCAudioEnabled(session.webRTCCallConfig);

    return (
      <div
        className={classNames(classes.deviceSettingsContainer, {
          [classes.spaceAround]:
            !webrtcAudioEnabled ||
            !window.CtxAppConfigurations.audioSwitchVisible
        })}
      >
        <IconButton
          className={classes.settingIconButton}
          onClick={this.props.showSettingsMenu}
          title={intl.formatMessage(getIntl("clickToOpenControlsWindow"))}
        >
          <SvgIcon iconName="controls" color="primaryBackgroundImageText" />
        </IconButton>
        {webrtcAudioEnabled &&
          window.CtxAppConfigurations.audioSwitchVisible &&
          (!isCM() || session.mergedCallState === "disconnected") && (
            <FormControlLabel
              classes={{
                label: classes.formControlLabel
              }}
              control={
                <Switch
                  id="audioSwitch"
                  checked={this.props.session.audioEnabled}
                  color={
                    this.props.session.audioEnabled ? "primary" : "secondary"
                  }
                  onChange={eventObj => this.handleAudioSwitchChange(eventObj)}
                />
              }
              label={<LocalizedText value="audio" variant="body1" />}
            />
          )}
        <FormControlLabel
          classes={{
            label: classes.formControlLabel
          }}
          control={
            <Switch
              id="videoSwitch"
              checked={this.props.session.webcamEnabled}
              onChange={eventObj => this.handleVideoSwitchChange(eventObj)}
              color={this.props.session.webcamEnabled ? "primary" : "secondary"}
            />
          }
          label={<LocalizedText value="video" variant="body1" />}
        />
      </div>
    );
  };

  renderRememberMe = () => {
    const { classes, session, recorder } = this.props;

    return (
      <Fragment>
        {(window.CtxAppConfigurations.customer === "TCAD" ||
          !session.pinRequired) &&
          !recorder && (
            <FormControlLabel
              classes={{
                label: classes.formControlLabel
              }}
              control={
                <Checkbox
                  className={classes.formCheckbox}
                  checked={this.state.rememberMe}
                  onChange={eventObj => this.handleCheckBoxChange(eventObj)}
                  color="primary"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      viewBox="3 3 18 18"
                      className={classes.checkboxOutlineBlankIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon
                      viewBox="3 3 18 18"
                      className={classes.checkboxIcon}
                    />
                  }
                />
              }
              label={<LocalizedText value="rememberMe" variant="body1" />}
            />
          )}
        {this.renderAudioOnlySettings()}
      </Fragment>
    );
  };

  pushUsernameAndUserDefinedFields = fieldNames => {
    fieldNames.push("username");
    if (window.CtxAppConfigurations.loginFieldVisibleUserDefined1) {
      fieldNames.push("userDefined1");
    }
    if (window.CtxAppConfigurations.loginFieldVisibleUserDefined2) {
      fieldNames.push("userDefined2");
    }
    if (window.CtxAppConfigurations.loginFieldVisibleUserDefined3) {
      fieldNames.push("userDefined3");
    }
    if (window.CtxAppConfigurations.loginFieldVisibleUserDefined4) {
      fieldNames.push("userDefined4");
    }
  };

  renderAudioOnlySettings = () => {
    const { classes, intl, session } = this.props;
    const webrtcAudioEnabled = isWebRTCAudioEnabled(session.webRTCCallConfig);
    const webrtcVideoEnabled = isVideoEnabled(session.videoConfig);

    return (
      !webrtcVideoEnabled &&
      webrtcAudioEnabled && (
        <Fragment>
          {window.CtxAppConfigurations.audioSwitchVisible && (
            <FormControlLabel
              classes={{
                label: classes.formControlLabel
              }}
              control={
                <Checkbox
                  id="audioSwitch"
                  className={classes.formCheckbox}
                  checked={this.props.session.audioEnabled}
                  onChange={eventObj => this.handleAudioSwitchChange(eventObj)}
                  color="primary"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      viewBox="3 3 18 18"
                      className={classes.checkboxOutlineBlankIcon}
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon
                      viewBox="3 3 18 18"
                      className={classes.checkboxIcon}
                    />
                  }
                />
              }
              label={
                <LocalizedText value="connectWithDevice" variant="body1" />
              }
            />
          )}
          <IconButton
            className={classes.audioSettingIconButton}
            onClick={this.props.showSettingsMenu}
            title={intl.formatMessage(getIntl("clickToOpenControlsWindow"))}
          >
            <SvgIcon iconName="controls" color="primaryBackgroundImageText" />
          </IconButton>
        </Fragment>
      )
    );
  };

  renderVideoAudioSetting = () => {
    const { classes, session } = this.props;
    const webrtcVideoEnabled = isVideoEnabled(session.videoConfig);
    return (
      webrtcVideoEnabled && (
        <div className={classes.videoContainer}>
          <video
            id="videoElement"
            poster={process.env.PUBLIC_URL + "/assets/videoMutePreview.png"}
            autoPlay
            playsInline
            muted
            controls={false}
            className={classes.video}
          />
          {this.renderDeviceSettings()}
        </div>
      )
    );
  };

  cmRenderDeviceSetting = () => {
    const { classes, session } = this.props;
    const webrtcVideoEnabled = isVideoEnabled(session.videoConfig);
    const webrtcAudioEnabled = isWebRTCAudioEnabled(session.webRTCCallConfig);

    return (
      <div>
        {this.renderVideoAudioSetting()}
        <div className={classes.rememberMeContainer}>
          {this.renderAudioOnlySettings()}
        </div>

        {(webrtcAudioEnabled || webrtcVideoEnabled) && (
          <div className={classes.buttonContainer}>
            <TextButton
              onClick={this.handleContinueButtonClick}
              className={classes.loginButton}
              type="button"
            >
              <LocalizedText value="continue" />
            </TextButton>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { classes, session, languages, agent, recorder } = this.props;
    const { isLoginFormSubmitting } = session;

    let fieldNames = [];
    if (window.CtxAppConfigurations.customer === "TCAD") {
      if (session.persistentSpaceName) {
        fieldNames.push("passcode");
        this.pushUsernameAndUserDefinedFields(fieldNames);
      } else if (agent) {
        fieldNames.push("agentID");
        this.pushUsernameAndUserDefinedFields(fieldNames);
      } else if (recorder) {
        fieldNames.push("passcode");
        fieldNames.push("recordingKey");
      } else {
        fieldNames.push("propertyID");
        this.pushUsernameAndUserDefinedFields(fieldNames);
      }
    } else if (recorder) {
      fieldNames.push("passcode");
      fieldNames.push("recordingKey");
    } else if (!session.persistentSpaceName) {
      fieldNames.push("passcode");
      if (window.CtxAppConfigurations.pinOptionalFieldVisible) {
        fieldNames.push("pinOptional");
      }
      this.pushUsernameAndUserDefinedFields(fieldNames);
    } else if (this.state.persistentHostLogin) {
      fieldNames.push("passcode");
    } else if (session.pinRequired) {
      fieldNames.push("pin");
    } else {
      this.pushUsernameAndUserDefinedFields(fieldNames);
    }

    const fields = loginFormFields.map(
      field =>
        fieldNames.includes(field.name) && (
          <Field
            key={field.name}
            name={field.name}
            component={this.renderTextField}
            field={field}
            props={languages}
            className={classes.fieldMargin}
          />
        )
    );

    const errorComponent = this.renderError(session.error);
    const generalLoginButtonComponent = (
      <TextButton
        type="submit"
        className={classes.loginButton}
        disabled={isLoginFormSubmitting}
      >
        <LocalizedText value="continue" />
      </TextButton>
    );
    const persistentGuestLoginButtonComponent = (
      <Fragment>
        <TextButton
          type="button"
          onClick={eventObj => this.handleContinueAsHost(eventObj, fieldNames)}
          className={classes.loginButton}
        >
          <LocalizedText value="continueAsHost" />
        </TextButton>
        <TextButton
          type="submit"
          className={classes.loginButton}
          disabled={isLoginFormSubmitting}
        >
          <LocalizedText value="continueAsGuest" />
        </TextButton>
      </Fragment>
    );
    const persistentHostLoginButtonComponent = (
      <Fragment>
        <TextButton
          onClick={this.handleBackButtonClick}
          className={classes.loginButton}
          type="button"
        >
          <LocalizedText value="back" />
        </TextButton>
        <TextButton
          type="submit"
          className={classes.loginButton}
          disabled={isLoginFormSubmitting}
        >
          <LocalizedText value="continue" />
        </TextButton>
      </Fragment>
    );

    let loginButtonComponents;
    if (
      session.persistentSpaceName &&
      window.CtxAppConfigurations.customer !== "TCAD" &&
      window.CtxAppConfigurations.hostPersistentSpaceLoginEnabled
    ) {
      loginButtonComponents = this.state.persistentHostLogin
        ? persistentHostLoginButtonComponent
        : persistentGuestLoginButtonComponent;
    } else {
      loginButtonComponents = generalLoginButtonComponent;
    }

    const formComponents = (
      <Fragment>
        <div>{fields}</div>
        <div className={classes.requiredLabelContainer}>
          <LocalizedText value="requiredFields" />
        </div>
        <div className={classes.rememberMeContainer}>
          {this.renderRememberMe()}
        </div>
        <div className={classes.errorContainer}>{errorComponent}</div>
        <div className={classes.buttonContainer}>{loginButtonComponents}</div>
      </Fragment>
    );

    const webrtcVideoEnabled = isVideoEnabled(session.videoConfig);

    return (
      <Fragment>
        <div className={classes.root}>
          <AppLogo classes={{ root: classes.logo }} logoType="body" />
          <div className={classes.mainDiv}>
            {!isCM() ? (
              <>
                {this.renderVideoAudioSetting()}
                <form
                  id="loginForm"
                  className={classNames(classes.form, {
                    [classes.videoForm]: webrtcVideoEnabled
                  })}
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
                >
                  {formComponents}
                </form>
              </>
            ) : (
              <>{this.cmRenderDeviceSetting()}</>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, languages, form }) => ({
  session,
  languages,
  form
});

const mapDispatchToProps = dispatch => ({
  login: (formValues, persistentSpaceName, onSuccess) =>
    dispatch(loginUser(formValues, persistentSpaceName)).then(userId =>
      onSuccess(userId)
    ),
  setIsLoginFormSubmitting: isSubmitting =>
    dispatch(setIsLoginFormSubmitting(isSubmitting)),
  setVideoInput: videoInput => dispatch(setVideoInput(videoInput)),
  setWebCamOn: () => dispatch(setWebCamOn()),
  setWebCamOff: () => dispatch(setWebCamOff()),
  setAudioOn: () => dispatch(setAudioOn()),
  setAudioOff: () => dispatch(setAudioOff()),
  showSettingsMenu: () => dispatch(showSettingsMenu()),
  clearLoginError: () => dispatch(clearLoginError()),
  setCMLoggedIn: cmLoggedIn => dispatch(setCMLoggedIn(cmLoggedIn)),
  setConnectPopperExpansion: payload =>
    dispatch(setConnectPopperExpansion(payload))
});

export default withStyles(styles)(
  injectIntl(
    reduxForm({
      form: formName,
      validate: values => validate(loginFormFields, values)
    })(withRoomContext(connect(mapStateToProps, mapDispatchToProps)(LoginForm)))
  )
);
