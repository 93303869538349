import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { hideSettingsMenu, setControlsTabView } from "../../actions";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import DeviceSettings from "./DeviceSettings";
import VideoDeviceSettings from "./VideoDeviceSettings";
import DataSharingSettings from "./DataSharingSettings";
import {
  isWebRTCAudioEnabled,
  isVideoEnabled,
  isMobileOrTablet
} from "../../utils";
import LocalizedText from "../reusable/LocalizedText";
import { Tabs, Tab, useMediaQuery } from "@material-ui/core";
import classNames from "classnames";

const styles = theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  headerText: {
    textAlign: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  container: {
    width: "calc(100% - 20px)",
    maxWidth: "440px"
  },
  bottomDiv: {
    height: "35px"
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column"
  },
  audioAndVideoHeight: {
    height: "453px"
  },
  audioOnlyHeight: {
    height: "222px"
  },
  videoOnlyHeight: {
    height: "279px"
  }
});

class SettingsMenuComponent extends Component {
  render() {
    const { classes, webRTCCallConfig, videoConfig, audioAndVideoDisplayable } =
      this.props;

    const audioDisplayed = isWebRTCAudioEnabled(webRTCCallConfig);
    let videoDisplayed = isVideoEnabled(videoConfig) && !isMobileOrTablet();

    if (!audioAndVideoDisplayable && audioDisplayed) {
      videoDisplayed = false;
    }

    let heightClass;
    if (audioDisplayed && videoDisplayed) {
      heightClass = classes.audioAndVideoHeight;
    } else if (audioDisplayed) {
      heightClass = classes.audioOnlyHeight;
    } else if (videoDisplayed) {
      heightClass = classes.videoOnlyHeight;
    }

    const component = (
      <Fragment>
        <div className={classes.header}>
          <LocalizedText
            value="userControls"
            variant="h6"
            className={classes.headerText}
          />
          <div className={classes.hrDiv} />
        </div>
        <div className={classNames(classes.mainContentContainer, heightClass)}>
          {this.getTabControls(audioDisplayed, videoDisplayed)}
          {this.getMainView(audioDisplayed, videoDisplayed)}
        </div>
        <div className={classes.bottomDiv} />
      </Fragment>
    );

    return (
      <CTXBaseWindow
        classes={{ container: classes.container }}
        component={component}
        overrideHandleClose={() => this.props.hideSettingsMenu()}
        disableClickAwayClose={true}
      />
    );
  }

  handleTabChange = (event, tabIndex) => {
    this.props.setControlsTabView(tabIndex);
  };

  getTabControls = (audioDisplayed, videoDisplayed) => {
    const { isLoggedIn, controlsTabIndex, userLevel } = this.props;
    const isHost = userLevel === "1";
    return (
      <Tabs
        value={controlsTabIndex}
        onChange={this.handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
      >
        {(audioDisplayed || videoDisplayed) && (
          <Tab label={<LocalizedText value="devices" variant="subtitle1" />} />
        )}
        {isLoggedIn &&
          (window.CtxAppConfigurations.guestScreenSharingEnabled || isHost) && (
            <Tab
              label={<LocalizedText value="applications" variant="subtitle1" />}
            />
          )}
      </Tabs>
    );
  };

  getMainView = (audioDisplayed, videoDisplayed) => {
    const { controlsTabIndex } = this.props;

    let res = null;

    if (!audioDisplayed && !videoDisplayed) {
      res = <DataSharingSettings />;
    } else if (controlsTabIndex === 0) {
      res = (
        <>
          {audioDisplayed && <DeviceSettings />}
          {videoDisplayed && <VideoDeviceSettings />}
        </>
      );
    } else if (controlsTabIndex === 1) {
      res = <DataSharingSettings />;
    }

    return res;
  };
}

function SettingsMenu(props) {
  const audioAndVideoDisplayable = useMediaQuery("(min-height: 600px)");

  return (
    <SettingsMenuComponent
      {...props}
      audioAndVideoDisplayable={audioAndVideoDisplayable}
    />
  );
}

const mapStateToProps = state => {
  return {
    controlsTabIndex: state.session.controlsTabIndex,
    internalDrawer: state.internalDrawer,
    webRTCCallConfig: state.session.webRTCCallConfig,
    videoConfig: state.session.videoConfig,
    isLoggedIn: state.session.isLoggedIn,
    userLevel: state.session.userLevel
  };
};

const mapDispatchToProps = dispatch => ({
  hideSettingsMenu: () => dispatch(hideSettingsMenu()),
  setControlsTabView: tabIndex => dispatch(setControlsTabView(tabIndex))
});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SettingsMenu))
);
