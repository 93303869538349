import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  Card,
  Typography,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
  CircularProgress
} from "@material-ui/core";
import { getIntl, getAvatarString } from "../../utils";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import { UserPromptLevel } from "./actions";
import CTXTextField from "../reusable/CTXTextField";

const styles = theme => ({
  root: {
    minWidth: "780px",
    display: "flex",
    flexDirection: "column"
  },
  rootFullHeight: {
    height: "100%"
  },
  rootBackgroundColor: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.primaryTextColor}`
  },
  headerAvatar: {
    width: "35px",
    height: "35px"
  },
  cardHeader: {
    padding: "16px 40px 5px 40px"
  },
  cardContent: {
    padding: "0px 40px 5px 40px",
    flexGrow: 1
  },
  profileControl: {
    padding: "10px 50px 0px 30px"
  },
  profileControlWidth: {
    width: "75%"
  },
  profileControlWidthNoPicture: {
    width: "95%"
  },
  pictureControl: {
    width: "25%"
  },
  textfieldGeneral: {
    width: "75%",
    marginTop: "8px"
  },
  enableModeTypography: {
    width: "75%",
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  persistentSpaceTextFieldContainer: {
    width: "75%"
  },
  persistentSpaceTextField: {
    width: "80px"
  },
  persistentSpaceEditButton: {
    marginLeft: "10px",
    height: "32px"
  },
  persistentSpaceEditButtoRoot: {
    minWidth: "20px",
    minHeight: "10px",
    marginBottom: "0px"
  },
  persistentSpaceRowContainer: {
    height: "48px"
  },
  persistentSpacePromptContainer: {
    marginLeft: "10px"
  },
  cardActions: {
    paddingLeft: "40px",
    paddingRight: "40px",
    flexDirection: "row-reverse"
  },
  buttonProgress: {
    position: "absolute",
    marginLeft: -105,
    marginTop: 5
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  }
});

class UserProfileCard extends Component {
  state = {
    editPersistentSpaceMode: false,
    textFieldUsername: "",
    textFieldFirstName: "",
    textFieldLastName: "",
    textFieldEmail: "",
    textFieldPersistentSpace: "",
    textFieldPassword: "",
    textFieldConfirmPassword: "",
    persistentSpaceUserPrompt: "",
    persistentSpaceUserPromptLevel: UserPromptLevel.INFO
  };

  constructor(props) {
    super(props);
    this.state = {
      editPersistentSpaceMode: false,
      textFieldUsername: this.props.username != null ? this.props.username : "",
      textFieldFirstName:
        this.props.firstName != null ? this.props.firstName : "",
      textFieldLastName: this.props.lastName != null ? this.props.lastName : "",
      textFieldEmail: this.props.email != null ? this.props.email : "",
      textFieldPersistentSpace:
        this.props.persistentSpaceName != null
          ? this.props.persistentSpaceName
          : "",
      textFieldPassword: this.props.password != null ? this.props.password : "",
      textFieldConfirmPassword:
        this.props.confirmPassword != null ? this.props.confirmPassword : "",
      persistentSpaceUserPrompt: "",
      persistentSpaceUserPromptLevel: UserPromptLevel.INFO
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userID != null) {
      if (prevProps.userID !== this.props.userID) {
        this.refreshAllTextFields();
      }
    }
  }

  //Card header
  getCardHeaderControl = () => {
    const { classes } = this.props;

    const fullName = this.props.firstName + " " + this.props.lastName;

    const headerAvatar = (
      <Avatar className={classes.headerAvatar}>
        <Typography variant="body1">{getAvatarString(fullName)}</Typography>
      </Avatar>
    );

    return (
      <CardHeader
        avatar={headerAvatar}
        title={fullName}
        titleTypographyProps={{ color: "inherit", variant: "h5" }}
        classes={{
          root: classes.cardHeader
        }}
      />
    );
  };

  //Card actions
  getCardActionsControl = () => {
    const { classes } = this.props;

    const actionControls = (
      <div>
        <TextButton
          disabled={this.props.buttonLoader}
          onClick={this.handleSaveButtonOnClick}
        >
          <LocalizedText
            value={
              this.props.saveButtonText ? this.props.saveButtonText : "save"
            }
          />
        </TextButton>
        {this.props.buttonLoader && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </div>
    );

    return (
      <CardActions
        disableSpacing
        classes={{
          root: classes.cardActions
        }}
      >
        {actionControls}
      </CardActions>
    );
  };

  //Card Content
  getCardContentControl = () => {
    const { classes } = this.props;

    return (
      <CardContent
        classes={{
          root: classes.cardContent
        }}
      >
        {!this.props.hideHeader && <div className={classes.hrDivPrimary} />}
        {this.getUserEditControl()}
      </CardContent>
    );
  };

  getUserEditControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.displayFlexRow}>
        {this.getProfileControl()}
        {!this.props.hidePicture && this.getUserPictureControl()}
      </div>
    );
  };

  getProfileControl = () => {
    const { classes, intl } = this.props;

    return (
      <div
        className={classNames(
          classes.profileControl,
          this.props.hidePicture
            ? classes.profileControlWidthNoPicture
            : classes.profileControlWidth
        )}
      >
        {this.getProfileGeneralEntryControl(
          intl.formatMessage(getIntl("username")),
          this.state.textFieldUsername,
          "UserProfileCardTextFieldUsername",
          this.handleTextFieldUsernameOnChange,
          "text",
          60,
          this.props.enableUserMode
        )}
        {this.getProfileGeneralEntryControl(
          intl.formatMessage(getIntl("firstName")),
          this.state.textFieldFirstName,
          "UserProfileCardTextFieldFirstName",
          this.handleTextFieldFirstNameOnChange,
          "text",
          60,
          this.props.enableUserMode
        )}
        {this.getProfileGeneralEntryControl(
          intl.formatMessage(getIntl("lastName")),
          this.state.textFieldLastName,
          "UserProfileCardTextFieldLastName",
          this.handleTextFieldLastNameOnChange,
          "text",
          60,
          this.props.enableUserMode
        )}
        {this.getProfileGeneralEntryControl(
          intl.formatMessage(getIntl("email")),
          this.state.textFieldEmail,
          "UserProfileCardTextFieldEmail",
          this.handleTextFieldEmailOnChange,
          "email",
          320,
          this.props.enableUserMode
        )}
        {!this.props.hidePassword &&
          this.getProfileGeneralEntryControl(
            intl.formatMessage(getIntl("newPassword")),
            this.state.textFieldPassword,
            "UserProfileCardTextFieldPassword",
            this.handleTextFieldPasswordOnChange,
            "password",
            64
          )}
        {!this.props.hidePassword &&
          this.getProfileGeneralEntryControl(
            intl.formatMessage(getIntl("confirmPassword")),
            this.state.textFieldConfirmPassword,
            "UserProfileCardTextFieldConfirmPassword",
            this.handleTextFieldConfirmPasswordOnChange,
            "password",
            64
          )}
        {!this.props.hidePersistentSpace &&
          this.getProfilePersistentSpaceEntryControl()}
        {this.props.enableGeneralPersistentSpaceEntry &&
          this.getProfileGeneralEntryControl(
            intl.formatMessage(getIntl("persistentSpace")),
            this.state.textFieldPersistentSpace,
            "UserProfileCardTextFieldPersistentSpace",
            this.handleTextFieldGeneralPersistentSpaceOnChange,
            "text",
            60
          )}
      </div>
    );
  };

  getProfileGeneralEntryControl = (
    labelName,
    textFieldValue,
    textFieldId,
    textFieldOnChangeFunc,
    textFieldType,
    textFieldLength,
    enableUserMode
  ) => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.justifyContentSpaceBetween
        )}
      >
        <Typography variant="body1" color="inherit">
          {labelName}
        </Typography>
        {enableUserMode === true ? (
          <div className={classes.enableModeTypography}>
            <Typography variant="body1" color="inherit">
              {textFieldValue}
            </Typography>
          </div>
        ) : (
          <CTXTextField
            id={textFieldId}
            value={textFieldValue}
            onChange={textFieldOnChangeFunc}
            className={classes.textfieldGeneral}
            margin="normal"
            type={textFieldType}
            inputProps={{
              maxLength: textFieldLength
            }}
          />
        )}
      </div>
    );
  };

  getProfilePersistentSpaceEntryControl = () => {
    const { classes, intl } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.justifyContentSpaceBetween,
          classes.persistentSpaceRowContainer
        )}
      >
        <Typography variant="body1" color="inherit">
          {intl.formatMessage(getIntl("persistentSpace"))}
        </Typography>
        <div className={classes.persistentSpaceTextFieldContainer}>
          {this.state.editPersistentSpaceMode ? (
            <div
              className={classNames(
                classes.displayFlexRow,
                classes.alignItemsCenter
              )}
            >
              <CTXTextField
                id="UserProfileCardTextFieldPersistentSpace"
                value={this.state.textFieldPersistentSpace}
                onChange={this.handleTextFieldPersistentSpaceOnChange}
                className={classes.persistentSpaceTextField}
                margin="normal"
                type="text"
                inputProps={{
                  maxLength: 60
                }}
              />
              <TextButton
                className={classes.persistentSpaceEditButton}
                classes={{ root: classes.persistentSpaceEditButtoRoot }}
                onClick={this.handlePersistentSpaceOKButtonOnClick}
              >
                <LocalizedText value="ok" />
              </TextButton>
              <TextButton
                className={classes.persistentSpaceEditButton}
                classes={{ root: classes.persistentSpaceEditButtoRoot }}
                onClick={this.handlePersistentSpaceCancelButtonOnClick}
                color="error"
              >
                <LocalizedText
                  value="cancel"
                  className={classes.errorTextColor}
                />
              </TextButton>
              <div className={classes.persistentSpacePromptContainer}>
                <Typography
                  variant="body2"
                  color={
                    this.state.persistentSpaceUserPromptLevel ===
                    UserPromptLevel.ERROR
                      ? "error"
                      : "inherit"
                  }
                >
                  {this.state.persistentSpaceUserPrompt}
                </Typography>
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                classes.displayFlexRow,
                classes.alignItemsCenter
              )}
            >
              <Typography variant="body1" color="inherit">
                {this.props.persistentSpaceName}
              </Typography>
              {this.props.persistentSpaceName != null &&
                this.props.persistentSpaceName !== "" && (
                  <TextButton
                    className={classes.persistentSpaceEditButton}
                    classes={{ root: classes.persistentSpaceEditButtoRoot }}
                    onClick={this.handlePersistentSpaceEditButtonOnClick}
                  >
                    <LocalizedText value="edit" />
                  </TextButton>
                )}
            </div>
          )}
        </div>
      </div>
    );
  };

  getUserPictureControl = () => {
    const { classes } = this.props;

    return <div className={classes.pictureControl}></div>;
  };

  //functions
  validateUserInputPersistentSpace = () => {
    const { intl } = this.props;
    //Empty field
    if (this.state.textFieldPersistentSpace.trim() === "") {
      this.setState({
        persistentSpaceUserPrompt: intl.formatMessage(
          getIntl("persistentSpaceNotEmpty")
        ),
        persistentSpaceUserPromptLevel: UserPromptLevel.ERROR
      });

      return false;
    }
    //Persistent Space
    if (!/^[a-z0-9]+$/i.test(this.state.textFieldPersistentSpace)) {
      this.setState({
        persistentSpaceUserPrompt: intl.formatMessage(
          getIntl("onlyLetterNumberPersistentSpace")
        ),
        persistentSpaceUserPromptLevel: UserPromptLevel.ERROR
      });

      return false;
    }

    return true;
  };

  validateUserInput = () => {
    //Empty field
    if (this.state.textFieldUsername.trim() === "") {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(UserPromptLevel.ERROR, "usernameNotEmpty");
      }
      return false;
    } else if (this.state.textFieldFirstName.trim() === "") {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(
          UserPromptLevel.ERROR,
          "firstNameNotEmpty"
        );
      }
      return false;
    } else if (this.state.textFieldLastName.trim() === "") {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(UserPromptLevel.ERROR, "lastNameNotEmpty");
      }
      return false;
    } else if (this.state.textFieldEmail.trim() === "") {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(UserPromptLevel.ERROR, "emailNotEmpty");
      }
      return false;
    }
    //Email
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.textFieldEmail)) {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(
          UserPromptLevel.ERROR,
          "loginFormErrorMessageInvalidEmailAddress"
        );
      }
      return false;
    }
    //Password === ConfirmPassword
    if (
      !this.props.hidePassword &&
      (this.state.textFieldPassword.trim() === "" ||
        this.state.textFieldPassword !== this.state.textFieldConfirmPassword)
    ) {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(
          UserPromptLevel.ERROR,
          "confimNewPassword"
        );
      }
      return false;
    }
    //GeneralPersistentSpaceEntry
    if (
      this.props.enableGeneralPersistentSpaceEntry &&
      this.state.textFieldPersistentSpace.trim() === ""
    ) {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(
          UserPromptLevel.ERROR,
          "persistentSpaceNotEmpty"
        );
      }
      return false;
    }

    return true;
  };

  isUserInfoChanged = () => {
    if (this.props.username !== this.state.textFieldUsername) {
      return true;
    } else if (this.props.firstName !== this.state.textFieldFirstName) {
      return true;
    } else if (this.props.lastName !== this.state.textFieldLastName) {
      return true;
    } else if (this.props.email !== this.state.textFieldEmail) {
      return true;
    } else if (
      this.state.textFieldPassword != null &&
      this.state.textFieldPassword !== ""
    ) {
      return true;
    }

    return false;
  };

  isPersistentSpaceChanged = () => {
    const { persistentSpaceName } = this.props;

    if (persistentSpaceName != null && persistentSpaceName.length > 0) {
      return !(this.state.textFieldPersistentSpace === persistentSpaceName);
    } else {
      return !(
        this.state.textFieldPersistentSpace == null ||
        this.state.textFieldPersistentSpace.trim() === ""
      );
    }
  };

  handleTextFieldUsernameOnChange = event => {
    this.setState({ textFieldUsername: event.target.value });
  };

  handleTextFieldFirstNameOnChange = event => {
    this.setState({ textFieldFirstName: event.target.value });
  };

  handleTextFieldLastNameOnChange = event => {
    this.setState({ textFieldLastName: event.target.value });
  };

  handleTextFieldEmailOnChange = event => {
    this.setState({ textFieldEmail: event.target.value });
  };

  handleTextFieldPersistentSpaceOnChange = event => {
    this.setState({ textFieldPersistentSpace: event.target.value });
  };

  handleTextFieldPasswordOnChange = event => {
    this.setState({ textFieldPassword: event.target.value });
  };

  handleTextFieldConfirmPasswordOnChange = event => {
    this.setState({ textFieldConfirmPassword: event.target.value });
  };

  handlePersistentSpaceEditButtonOnClick = () => {
    this.setState({
      editPersistentSpaceMode: true,
      textFieldPersistentSpace: this.props.persistentSpaceName,
      persistentSpaceUserPrompt: ""
    });
  };

  handleTextFieldGeneralPersistentSpaceOnChange = event => {
    this.setState({ textFieldPersistentSpace: event.target.value });
  };

  handlePersistentSpaceOKButtonOnClick = () => {
    const { intl } = this.props;
    //Check user input
    if (this.validateUserInputPersistentSpace() === false) {
      return;
    }

    if (!this.isPersistentSpaceChanged()) {
      this.setState({
        persistentSpaceUserPrompt: intl.formatMessage(
          getIntl("noInformationUpdated")
        ),
        persistentSpaceUserPromptLevel: UserPromptLevel.ERROR
      });

      return;
    }

    if (this.props.setFeedbackPrompt != null) {
      this.props.setFeedbackPrompt(UserPromptLevel.INFO, "updatingPleaseWait");
    }

    if (this.props.updatePersistentSpaceName != null) {
      this.props.updatePersistentSpaceName(
        this.props.portalSessionId,
        this.state.textFieldPersistentSpace
      );
    } else if (this.props.updatePersistentSpaceNameByUserID != null) {
      this.props.updatePersistentSpaceNameByUserID(
        this.props.portalSessionId,
        this.props.userID,
        this.state.textFieldPersistentSpace
      );
    }

    this.setState({
      editPersistentSpaceMode: false
    });
  };

  handlePersistentSpaceCancelButtonOnClick = () => {
    this.setState({
      editPersistentSpaceMode: false
    });
  };

  handleSaveButtonOnClick = () => {
    if (this.state.editPersistentSpaceMode) {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(
          UserPromptLevel.ERROR,
          "savePersistentSpaceFirst"
        );
      }
      return;
    }

    //validate user input
    if (this.validateUserInput() === false) {
      return;
    }

    if (!this.props.enableUserMode && !this.isUserInfoChanged()) {
      if (this.props.setFeedbackPrompt != null) {
        this.props.setFeedbackPrompt(
          UserPromptLevel.ERROR,
          "noInformationUpdated"
        );
      }
      return;
    }

    if (this.props.setFeedbackPrompt != null) {
      this.props.setFeedbackPrompt(UserPromptLevel.INFO, "updatingPleaseWait");
    }

    const portalUserObj = {
      username: this.state.textFieldUsername,
      password: this.state.textFieldPassword,
      firstName: this.state.textFieldFirstName,
      lastName: this.state.textFieldLastName,
      email: this.state.textFieldEmail,
      persistentSpaceName: this.props.enableGeneralPersistentSpaceEntry
        ? this.state.textFieldPersistentSpace
        : undefined
    };

    if (this.props.updateUserProfile != null) {
      this.props.updateUserProfile(this.props.portalSessionId, portalUserObj);
    } else if (this.props.updateUserProfileByUserID != null) {
      this.props.updateUserProfileByUserID(
        this.props.portalSessionId,
        portalUserObj,
        this.props.userID,
        this.props.session.portalUser.userIdDB
      );

      if (this.props.setUserManagementSelectedUser != null) {
        this.props.setUserManagementSelectedUser(undefined);
      }
    } else if (this.props.newUserNextPage != null) {
      this.props.newUserNextPage(portalUserObj);
    }

    if (!this.props.disableClearPasswordOnSave)
      this.setState({
        textFieldPassword: "",
        textFieldConfirmPassword: ""
      });
  };

  refreshAllTextFields = () => {
    this.setState({
      textFieldUsername: this.props.username != null ? this.props.username : "",
      textFieldFirstName:
        this.props.firstName != null ? this.props.firstName : "",
      textFieldLastName: this.props.lastName != null ? this.props.lastName : "",
      textFieldEmail: this.props.email != null ? this.props.email : "",
      textFieldPersistentSpace:
        this.props.persistentSpaceName != null
          ? this.props.persistentSpaceName
          : "",
      textFieldPassword: "",
      textFieldConfirmPassword: ""
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={classNames(
          classes.root,
          this.props.fullHeight ? classes.rootFullHeight : undefined,
          this.props.backgroundColor ? undefined : classes.rootBackgroundColor
        )}
        style={{
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : undefined
        }}
      >
        {!this.props.hideHeader && this.getCardHeaderControl()}
        {this.getCardContentControl()}
        {this.getCardActionsControl()}
      </Card>
    );
  }
}

const mapStateToProps = ({ session }) => ({ session });

const mapDispatchToProps = dispatch => ({});

UserProfileCard.propTypes = {
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  persistentSpaceName: PropTypes.string,
  updatePersistentSpaceName: PropTypes.func,
  updatePersistentSpaceNameByUserID: PropTypes.func,
  updateUserProfile: PropTypes.func,
  updateUserProfileByUserID: PropTypes.func,
  setFeedbackPrompt: PropTypes.func
};

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserProfileCard))
  )
);
