import React, { Component, Fragment } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  Card,
  Typography,
  CardContent,
  CardHeader,
  CardActions,
  Avatar,
  IconButton,
  InputBase
} from "@material-ui/core";
import classNames from "classnames";
import {
  MoreVert,
  QuestionAnswerOutlined,
  ArrowBack,
  Edit,
  Delete
} from "@material-ui/icons";
import {
  handleAnswerSend,
  handleAnswerDelete,
  handleAnswerEdit
} from "./actions";
import PropTypes from "prop-types";
import {
  isHost,
  getIntl,
  TitleNewLine,
  convertUTCtoLocalTime,
  getAvatarString
} from "../../utils";
import QuestionCardOptionMenu from "./QuestionCardOptionMenu";
import SvgIcon from "../Icons/SvgIcon";
import CTXTextField from "../reusable/CTXTextField";

const styles = theme => ({
  root: {
    backgroundColor: theme.colors.primaryBackgroundColor,
    margin: "5px 20px 0px 10px"
  },
  hrDivPrimary: {
    borderTop: `1px solid ${theme.colors.secondaryMainColor}`
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  questionAnswerDivider: {
    marginTop: "5px"
  },
  cardContent: {
    padding: "0px 16px 0px 16px"
  },
  cardContentAnswer: {
    padding: "10px 16px 0px 16px"
  },
  cardActions: {
    padding: "1px 16px 0px 2px"
  },
  answersControl: {
    display: "block",
    height: "70px"
  },
  msgBoxContainer: {
    width: "100%"
  },
  inputBaseContainer: {
    flexGrow: "1",
    alignSelf: "center"
  },
  msgBox: {
    color: theme.colors.primaryTextColor
  },
  msgBoxPadding: {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  iconButton: {
    width: "1.4em",
    padding: "6px"
  },
  rowBodyActionIcon: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  answersMargin: {
    marginBottom: "5px"
  },
  mixViewCardHeader: {
    padding: "2px 0px 5px 10px"
  },
  mixViewCardContent: {
    padding: "0px 16px 0px 10px"
  },
  mixViewCardContentAnswer: {
    padding: "2px 0px 0px 10px"
  },
  mixViewCardActions: {
    padding: "1px 0px 0px 2px"
  },
  mixViewAnswerActionButton: {
    margin: "0px"
  },
  flexGrow: {
    flexGrow: "1"
  },
  denseIconButton: {
    padding: "3px"
  },
  fullWidth: {
    width: "100%"
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end"
  },
  answerAvatar: {
    marginRight: "16px",
    width: "30px",
    height: "30px"
  },
  alignSelfFlexStart: {
    alignSelf: "flex-start"
  },
  questionSvgIcon: {
    width: "20px",
    marginRight: "8px",
    flexShrink: 0,
    flexGrow: 0
  }
});

/*questionId: int
 *questionerName: string
 *questionCreateTime: string
 *questionContents: string
 *answerObjArray: object[answer]
 *lightViewEnabled: boolean
 */

class QuestionCard extends Component {
  constructor(props) {
    super(props);
    this.inputBaseRef = React.createRef();
  }

  state = {
    inputBaseVisible: false,
    answer: "",
    anchorEl: null,
    selectedQuestionId: null,
    editingAnswer: false,
    editingAnswerId: -1,
    editingAnswerContents: ""
  };

  //Card header
  getCardHeaderControl = () => {
    const {
      classes,
      questionerName,
      questionCreateTime,
      questionId,
      session,
      qa,
      intl
    } = this.props;
    const host = isHost(session);
    const { dataQAState } = qa;

    const headerAvatar = (
      <Avatar>
        <Typography variant="h6">{getAvatarString(questionerName)}</Typography>
      </Avatar>
    );
    const headerMoreButton = host && (
      <div>
        <IconButton
          color={dataQAState ? "inherit" : "secondary"}
          onClick={event => {
            this.handleQuestionCardDotsButtonOnClick(event, questionId);
          }}
          title={
            dataQAState
              ? intl.formatMessage(getIntl("moreOptions"))
              : intl.formatMessage(getIntl("webQAInactive"))
          }
        >
          <MoreVert />
        </IconButton>
      </div>
    );

    return (
      <CardHeader
        avatar={headerAvatar}
        action={headerMoreButton}
        title={questionerName ? questionerName : "???"}
        titleTypographyProps={{ color: "inherit", variant: "subtitle1" }}
        subheader={questionCreateTime ? questionCreateTime : "???"}
        subheaderTypographyProps={{ color: "inherit", variant: "caption" }}
        classes={{
          root: this.props.lightViewEnabled
            ? classes.mixViewCardHeader
            : undefined
        }}
      />
    );
  };

  //Card questions
  getCardQuestionControl = () => {
    const { classes, answerObjArray } = this.props;
    const { questionContents } = this.props;

    return (
      <CardContent
        classes={{
          root: this.props.lightViewEnabled
            ? classes.mixViewCardContent
            : classes.cardContent
        }}
      >
        <div className={classes.displayFlexRow}>
          <div className={classes.questionSvgIcon}>
            <SvgIcon
              iconName="qaQuestion"
              color={
                answerObjArray != null && answerObjArray.length > 0
                  ? "connect"
                  : "active"
              }
            />
          </div>
          <Typography
            color="inherit"
            variant="subtitle2"
            style={{ wordBreak: "break-all" }}
          >
            {questionContents ? questionContents : "???"}
          </Typography>
        </div>
      </CardContent>
    );
  };

  //Card answers
  getCardAnswerControl = () => {
    const { classes } = this.props;

    const answersControls = this.getAnswersControl();

    return (
      answersControls.length > 0 && (
        <Fragment>
          <div
            className={classNames(
              classes.hrDivPrimary,
              this.props.lightViewEnabled
                ? undefined
                : classes.questionAnswerDivider
            )}
          />
          <CardContent
            classes={{
              root: this.props.lightViewEnabled
                ? classes.mixViewCardContentAnswer
                : classes.cardContentAnswer
            }}
          >
            {answersControls}
          </CardContent>
        </Fragment>
      )
    );
  };

  getAnswersControl = () => {
    const { classes, answerObjArray, qa, session, intl } = this.props;
    const { dataQAState } = qa;
    const host = isHost(session);
    const { editingAnswer, editingAnswerId } = this.state;
    const { id } = session;

    let answersControls = [];

    if (answerObjArray != null && answerObjArray.length > 0) {
      answerObjArray.forEach((ans, index) => {
        const answerUserName = ans.owner.username;
        const answerOwnerId = ans.owner.id;
        const answerContents = ans.contents;
        const answerId = ans.id;
        const answerDateTime = ans.answerCreateTime;

        const nameAvatar = (
          <Avatar
            className={classes.answerAvatar}
            title={
              answerUserName +
              TitleNewLine +
              convertUTCtoLocalTime(answerDateTime)
            }
          >
            <Typography variant="body2">
              {getAvatarString(answerUserName)}
            </Typography>
          </Avatar>
        );

        answersControls.push(
          <div
            key={index}
            className={classNames(
              classes.displayFlexRow,
              classes.alignItemsCenter,
              classes.fullWidth,
              this.props.lightViewEnabled ? undefined : classes.answersMargin
            )}
          >
            <div className={classNames(classes.flexGrow)}>
              {editingAnswer && editingAnswerId === answerId ? (
                <CTXTextField
                  id="webqa-answer-edit-textfield"
                  fullWidth
                  autoFocus
                  value={this.state.editingAnswerContents}
                  onChange={this.handleEditingTextfieldOnChange}
                  onKeyPress={this.handleEditingTextfieldOnKeyPress}
                />
              ) : (
                <div className={classes.displayFlexRow}>
                  {nameAvatar}
                  <Typography
                    color="inherit"
                    variant="body2"
                    inline
                    style={{ wordBreak: "break-all" }}
                  >
                    {answerContents}
                  </Typography>
                </div>
              )}
            </div>
            {host && (
              <Fragment>
                {editingAnswer && editingAnswerId === answerId ? (
                  <IconButton
                    className={classNames(
                      classes.iconButton,
                      classes.rowBodyActionIcon
                    )}
                    color="inherit"
                    onClick={this.handleCancelEditButtonOnClick}
                    title={intl.formatMessage(getIntl("back"))}
                  >
                    <ArrowBack />
                  </IconButton>
                ) : (
                  <div
                    className={classNames(
                      classes.displayFlexRow,
                      classes.flexGrow,
                      classes.justifyContentFlexEnd,
                      classes.alignSelfFlexStart
                    )}
                  >
                    {answerOwnerId === id && (
                      <IconButton
                        className={classNames(
                          classes.iconButton,
                          classes.rowBodyActionIcon,
                          this.props.lightViewEnabled
                            ? classes.mixViewAnswerActionButton
                            : undefined
                        )}
                        color={dataQAState ? "inherit" : "secondary"}
                        onClick={event =>
                          this.handleEditAnswerButtonOnClick(
                            event,
                            answerId,
                            answerContents
                          )
                        }
                        title={
                          dataQAState
                            ? intl.formatMessage(getIntl("edit"))
                            : intl.formatMessage(getIntl("webQAInactive"))
                        }
                      >
                        <Edit />
                      </IconButton>
                    )}
                    <IconButton
                      className={classNames(
                        classes.iconButton,
                        classes.rowBodyActionIcon,
                        this.props.lightViewEnabled
                          ? classes.mixViewAnswerActionButton
                          : undefined
                      )}
                      color={dataQAState ? "inherit" : "secondary"}
                      onClick={event =>
                        this.handleDeleteAnswerButtonOnClick(event, answerId)
                      }
                      title={
                        dataQAState
                          ? intl.formatMessage(getIntl("delete"))
                          : intl.formatMessage(getIntl("webQAInactive"))
                      }
                    >
                      <Delete />
                    </IconButton>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        );
      });
    }

    return answersControls;
  };

  //Card actions
  getCardActionsControl = () => {
    const { classes, qa, session, intl } = this.props;
    const { inputBaseVisible, answer } = this.state;
    const { dataQAState } = qa;
    const host = isHost(session);

    const actionControls =
      host &&
      (inputBaseVisible ? (
        <div
          className={classNames(
            classes.msgBoxContainer,
            classes.displayFlexRow
          )}
        >
          <div className={classes.inputBaseContainer}>
            <InputBase
              id="questionCardMessageBox"
              inputRef={this.inputBaseRef}
              autoFocus
              className={classNames(classes.msgBox, classes.msgBoxPadding)}
              fullWidth={true}
              value={answer}
              onChange={this.setAnswerContents}
              onKeyPress={this.handleInputBaseOnKeyPress}
              placeholder={
                dataQAState
                  ? intl.formatMessage(
                      getIntl("webQAQuestionCardAnswerTextFieldPlaceholder")
                    )
                  : intl.formatMessage(getIntl("webQAInactive"))
              }
            />
          </div>
          <IconButton
            color="inherit"
            onClick={this.handleArrowBackOnClick}
            classes={{
              root: this.props.lightViewEnabled
                ? classes.denseIconButton
                : undefined
            }}
            title={intl.formatMessage(getIntl("back"))}
          >
            <ArrowBack />
          </IconButton>
        </div>
      ) : (
        <IconButton
          color={dataQAState ? "inherit" : "secondary"}
          onClick={this.handleAnswerButtonOnClick}
          classes={{
            root: this.props.lightViewEnabled
              ? classes.denseIconButton
              : undefined
          }}
          title={
            dataQAState
              ? intl.formatMessage(getIntl("clickToAnswerQuestion"))
              : intl.formatMessage(getIntl("webQAInactive"))
          }
        >
          <QuestionAnswerOutlined />
        </IconButton>
      ));

    return (
      <CardActions
        disableSpacing
        classes={{
          root: this.props.lightViewEnabled
            ? classes.mixViewCardActions
            : classes.cardActions
        }}
      >
        {actionControls}
      </CardActions>
    );
  };

  //Option Menu
  getQuestionCardOptionMenuControl = () => {
    const { anchorEl, selectedQuestionId } = this.state;
    const open = Boolean(anchorEl);

    return (
      <QuestionCardOptionMenu
        id="question-card-option-menu"
        questionId={selectedQuestionId}
        anchorEl={anchorEl}
        open={open}
        handleClose={() => {
          this.setState({ anchorEl: undefined });
        }}
      />
    );
  };

  //function
  handleAnswerButtonOnClick = () => {
    const { qa } = this.props;
    const { dataQAState } = qa;

    if (dataQAState) {
      this.setState({ inputBaseVisible: true });
    }
  };

  handleArrowBackOnClick = () => {
    this.setState({ inputBaseVisible: false });
  };

  handleInputBaseOnKeyPress = event => {
    if (event.key === "Enter") {
      this.sendAnswer(this.state.answer);
      this.setState({ inputBaseVisible: false, answer: "" });
      event.preventDefault();
    }
  };

  sendAnswer = answer => {
    if (answer != null && answer.length > 0) {
      const { session } = this.props;
      const { userId } = session;

      this.props.handleAnswerSend(userId, this.props.questionId, answer);
    }
  };

  setAnswerContents = event => {
    this.setState({
      answer: event.target.value
    });
  };

  handleDeleteAnswerButtonOnClick = (event, answerId) => {
    const { questionId, session, qa } = this.props;
    const { userId } = session;
    const { dataQAState } = qa;

    if (dataQAState) {
      this.props.handleAnswerDelete(userId, questionId, answerId);
    }
  };

  handleQuestionCardDotsButtonOnClick = (event, questionId) => {
    const { qa } = this.props;
    const { dataQAState } = qa;

    if (dataQAState) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedQuestionId: questionId
      });
    }
  };

  handleEditAnswerButtonOnClick = (event, answerId, answerContents) => {
    const { qa } = this.props;
    const { dataQAState } = qa;

    if (dataQAState) {
      this.setState({
        editingAnswer: true,
        editingAnswerId: answerId,
        editingAnswerContents: answerContents
      });
    }
  };

  handleEditingTextfieldOnChange = event => {
    this.setState({ editingAnswerContents: event.target.value });
  };

  handleCancelEditButtonOnClick = () => {
    this.setState({
      editingAnswer: false
    });

    if (this.props.resizeParentContainer != null) {
      this.props.resizeParentContainer();
    }
  };

  handleEditingTextfieldOnKeyPress = event => {
    if (event.key === "Enter") {
      const { questionId, session, qa } = this.props;
      const { userId } = session;
      const { dataQAState } = qa;

      const { editingAnswerContents, editingAnswerId } = this.state;

      if (dataQAState) {
        this.props.handleAnswerEdit(
          userId,
          questionId,
          editingAnswerId,
          editingAnswerContents
        );
      }

      this.setState({
        editingAnswer: false
      });

      event.preventDefault();
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.root} id={this.props.id}>
        {this.getCardHeaderControl()}
        {this.getCardQuestionControl()}
        {this.getCardAnswerControl()}
        <div
          className={classNames(
            classes.hrDivPrimary,
            this.props.lightViewEnabled
              ? undefined
              : classes.questionAnswerDivider
          )}
        />
        {this.getCardActionsControl()}
        {this.getQuestionCardOptionMenuControl()}
      </Card>
    );
  }
}

const mapStateToProps = ({ session, qa }) => ({ session, qa });

const mapDispatchToProps = dispatch => ({
  handleAnswerSend: (userId, questionId, answer) =>
    dispatch(handleAnswerSend(userId, questionId, answer)),
  handleAnswerDelete: (userId, questionId, answer) =>
    dispatch(handleAnswerDelete(userId, questionId, answer)),
  handleAnswerEdit: (userId, questionId, answerId, answer) =>
    dispatch(handleAnswerEdit(userId, questionId, answerId, answer))
});

QuestionCard.propTypes = {
  questionId: PropTypes.number.isRequired
};

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(QuestionCard))
  )
);
