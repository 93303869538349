import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppLogo from "../Icons/AppLogo";
import LocalizedText from "../reusable/LocalizedText";
import { isHoldStateApplicable } from "../../utils";
import Peer from "../mediasoup/components/Peer";
import Me from "../mediasoup/components/Me";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "330px",
    color: theme.colors.primaryBackgroundImageTextColor
  },
  logo: {
    maxHeight: "200px",
    width: "100%",
    objectFit: "contain",
    marginBottom: "20px"
  },
  sharingDiv: {
    height: "100%",
    width: "100%"
  },
  sharedView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    margin: "auto"
  },
  greetingContainer: {
    padding: "5px",
    fontSize: "large",
    textAlign: "center",
    backgroundColor: `${theme.colors.sideBarBackgroundColor}`,
    color: `${theme.colors.primaryTextColor}`,
    border: `2px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "10px"
  },
  sharerView: {
    position: "absolute",
    height: "200px",
    width: "400px",
    right: "64px",
    top: "64px"
  }
});

class GuestGreeting extends Component {
  render() {
    const {
      classes,
      conference,
      session,
      screenSharePeer,
      screenShareProducer,
      screenShareConsumer
    } = this.props;
    const isSharingActive = session.sharingActive;
    const isSharer = session.isSharer;
    const holdState = isHoldStateApplicable(conference, session);

    if (isSharingActive && !isSharer && screenShareConsumer) {
      return <Peer id={screenSharePeer.id} screenShare />;
    } else if (isSharingActive && isSharer && screenShareProducer) {
      return (
        <>
          <div className={classes.sharerView}>
            <Me screenShare />
          </div>
          <div className={classes.root}>
            <AppLogo classes={{ root: classes.logo }} logoType="body" />
            <LocalizedText
              value="sharerGreeting"
              className={classes.greetingContainer}
            />
          </div>
        </>
      );
    } else if (holdState) {
      return (
        <div className={classes.root}>
          <AppLogo classes={{ root: classes.logo }} logoType="body" />
          <LocalizedText
            value="guestConferenceHoldGreeting"
            className={classes.greetingContainer}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <AppLogo classes={{ root: classes.logo }} logoType="body" />
          <LocalizedText
            value="guestGreeting"
            className={classes.greetingContainer}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = ({
  conference,
  session,
  peers,
  producers,
  consumers
}) => {
  const producersArray = Object.values(producers);
  const screenShareProducer = producersArray.find(
    producer => producer.track.kind === "video" && producer.screenShare
  );

  let screenShareConsumer;
  const screenSharePeer = peers[session.sharingUserId];
  if (screenSharePeer != null) {
    const consumersArray = screenSharePeer.consumers.map(
      consumerId => consumers[consumerId]
    );
    screenShareConsumer = consumersArray.find(
      consumer => consumer.track.kind === "video" && consumer.screenShare
    );
  }

  return {
    conference,
    session,
    screenSharePeer,
    screenShareProducer,
    screenShareConsumer
  };
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(GuestGreeting))
);
