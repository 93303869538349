import React, { Component, Fragment } from "react";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { alpha } from "@material-ui/core/styles/colorManipulator";

import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { connect } from "react-redux";
import { createAndCallParty } from "./../../actions/index";
import { updateCallState } from "./../../actions/index";
import contexWebRest from "../../api/contexWebRest";
import { isHost, getIntl, isCM } from "../../utils";
import { disconnectAndRemoveParty } from "../invite";
import { REMEMBER_CALL_ME } from "../forms/LoginForm";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Logger from "../../Logger";
import CTXTextField from "../reusable/CTXTextField";

const logger = new Logger("CallMe");

const styles = theme => ({
  root: {
    alignSelf: "flex-start",
    marginBottom: "10px"
  },
  telephoneInput: {
    width: "100%"
  },
  telephoneInputLarge: {
    height: "220px"
  },
  telephoneInputMedium: {
    height: "86px"
  },
  telephoneInputSmall: {
    height: "60px"
  },
  flagMessage: {
    marginTop: "10px",
    marginBottom: "10px",
    alignSelf: "center"
  },
  intlTelInput: {
    width: "100%",
    marginBottom: "5px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "13px",
    "& .selected-flag": {
      outline: "none"
    },
    "& .country-list": {
      backgroundColor: theme.colors.telephoneInputBackgroundColor,
      color: theme.colors.telephoneInputTextColor,
      borderColor: alpha(theme.colors.telephoneInputTextColor, 0.2),
      // It is important for the country-list to be relatively positioned for it to be visible above the elements underneath
      position: "relative",
      whiteSpace: "normal",
      marginTop: "10px",
      maxHeight: "180px"
    },
    "& .country-list .divider": {
      borderBottomColor: alpha(theme.colors.telephoneInputTextColor, 0.2)
    },
    "& .country-list .country.highlight": {
      backgroundColor: alpha(theme.colors.telephoneInputTextColor, 0.1)
    },
    "& .country-list .country .dial-code": {
      color: alpha(theme.colors.telephoneInputTextColor, 0.5)
    }
  },
  input: {
    width: "100%",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "16px",
    backgroundColor: "transparent",
    color: theme.colors.primaryTextColor,
    border: "none",
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.primaryTextColor,
    fontWeight: 300,
    outline: "none",
    "&::placeholder": {
      color: alpha(theme.colors.primaryTextColor, 0.7),
      // Needed for Firefox
      opacity: 1
    },
    // IE
    "&:-ms-input-placeholder": {
      color: alpha(theme.colors.primaryTextColor, 0.7)
    },
    // Edge
    "&::-ms-input-placeholder": {
      color: alpha(theme.colors.primaryTextColor, 0.7)
    }
  },
  cmTelephoneInput: {
    marginBottom: "5px"
  },
  error: {
    "&$error": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.error.main
    },
    "&$error::placeholder": {
      color: theme.palette.error.main
    }
  },
  connectingMessage: {
    marginBottom: "30px",
    alignSelf: "center"
  },
  rememberPhoneNumberLabel: {
    color: theme.colors.primaryTextColor
  },
  rememberPhoneNumberCheckbox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.primaryMainTextColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  },
  rememberPhoneNumberContainer: {
    width: "100%"
  },
  noSelect: {
    userSelect: "none"
  }
});

class CallMe extends Component {
  constructor(props) {
    super(props);
    this.phoneNumberDivRef = React.createRef();
    this.intlTelInputRef = React.createRef();

    this.state = {
      phoneNumberValid: null,
      phoneNumber: "",
      error: false,
      rememberPhoneNumber:
        localStorage.getItem(REMEMBER_CALL_ME) != null &&
        localStorage.getItem(REMEMBER_CALL_ME).trim() !== ""
    };

    //set phone number to IntlTelInput component and local state
    if (localStorage.getItem(REMEMBER_CALL_ME)) {
      this.state.phoneNumber = localStorage.getItem(REMEMBER_CALL_ME);
      this.state.phoneNumberValid = true;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.session.callState === "Connecting" &&
      this.props.session.confActive &&
      !prevProps.session.confActive
    ) {
      //conference has been activated during a call request so we can now proceed to call
      this.props.call(
        this.createParty(this.props.session, this.state),
        this.props.session.userId
      );
    }
  }

  componentDidMount() {
    if (!isCM()) {
      //press enter to make a call
      this.phoneNumberDivRef.addEventListener("keydown", this.handleKeyPress);

      //set phone number to IntlTelInput component and local state
      if (localStorage.getItem(REMEMBER_CALL_ME)) {
        this.intlTelInputRef.current.setNumber(
          localStorage.getItem(REMEMBER_CALL_ME)
        );
      }
    }
  }

  renderRememberPhoneNumber = () => {
    const { classes } = this.props;

    return (
      <FormControlLabel
        classes={{
          label: classNames(classes.rememberPhoneNumberLabel, classes.noSelect)
        }}
        control={
          <Checkbox
            className={classes.rememberPhoneNumberCheckbox}
            checked={this.state.rememberPhoneNumber}
            onChange={eventObj => this.handleCheckBoxChange(eventObj)}
            color="primary"
            icon={
              <CheckBoxOutlineBlankIcon
                viewBox="3 3 18 18"
                className={classes.checkboxOutlineBlankIcon}
              />
            }
            checkedIcon={
              <CheckBoxIcon
                viewBox="3 3 18 18"
                className={classes.checkboxIcon}
              />
            }
          />
        }
        label={
          <LocalizedText value="rememberThisPhoneNumber" variant="body1" />
        }
      />
    );
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.handleButtonClick();
    }
  };

  createParty = (session, state) => {
    return {
      name: session.username,
      userDefined: session.userDefined,
      userDefined2: session.userDefined2,
      userDefined3: session.userDefined3,
      userDefined4: session.userDefined4,
      phoneNumber: state.phoneNumber,
      moderator: isHost(session) ? 1 : 0
    };
  };

  activateConf = userId => {
    const uri = `/conference/activate/${userId}`;
    let props = this.props;
    contexWebRest.post(uri).catch(function (error) {
      props.updateCallState("NotConnected");
      clearTimeout(props.session.timeout);
      logger.error("Error: %o", error);
    });
  };

  handlePhoneNumberChange = (valid, value, countryData, number) => {
    this.setState({
      phoneNumberValid: valid,
      phoneNumber: number,
      error: false,
      duplicatePhoneNumber: false
    });
  };

  handleCMPhoneNumberChange = event => {
    this.setState({
      phoneNumberValid: event.target.value.length > 0,
      phoneNumber: event.target.value,
      error: false,
      duplicatePhoneNumber: false
    });
  };

  handlePhoneNumberBlur = () => {
    if (!this.state.duplicatePhoneNumber) {
      this.setState({
        error: !this.state.phoneNumberValid
      });
    }
  };

  handleSelectFlag = (value, countryData, number, valid) => {
    this.setState({
      phoneNumberValid: valid,
      phoneNumber: number,
      error: false,
      duplicatePhoneNumber: false
    });
  };

  callParty = () => {
    if (this.state.phoneNumberValid) {
      const party = this.createParty(this.props.session, this.state);
      if (!this.checkDuplicateParty(party.name, party.phoneNumber)) {
        this.props.updateCallState("Connecting");
        if (this.props.session.confActive) {
          this.handleSaveDeletePhoneNumber();
          this.props.call(party, this.props.session.userId);
        } else {
          this.activateConf(this.props.session.userId);
        }
        this.setState({
          error: false
        });
      } else {
        this.setState({
          error: true,
          duplicatePhoneNumber: true
        });
      }
    } else {
      this.setState({
        error: true
      });
    }
  };

  disconnectCall = () => {
    this.props.disconnectCall(
      this.state.currentParty,
      this.props.session.userId
    );
  };

  checkDuplicateParty = (name, phoneNumber) => {
    logger.debug("Checking for duplicate party");
    let participants = this.props.participants.parties;
    for (let i = 0; i < participants.length; i++) {
      var existingParty = participants[i];
      if (
        name === existingParty.name &&
        phoneNumber === existingParty.phoneNumber &&
        existingParty.connectState !== "NotConnected"
      ) {
        return true;
      }
    }
    return false;
  };

  handleButtonClick = () => {
    const { session } = this.props;
    const { callState } = session;

    switch (callState) {
      case "NotConnected":
        this.callParty();
        break;
      case "Connecting":
        this.handleCancelCall();
        break;
      default:
        break;
    }
  };

  handleCancelCall = () => {
    const { session } = this.props;
    const { partyID, userId } = session;

    this.props.disconnectAndRemoveParty(partyID, userId);
  };

  handleSaveDeletePhoneNumber = () => {
    if (this.state.rememberPhoneNumber) {
      localStorage.setItem(REMEMBER_CALL_ME, this.state.phoneNumber);
    } else {
      localStorage.removeItem(REMEMBER_CALL_ME);
    }
  };

  handleCheckBoxChange = eventObj => {
    this.setState({
      rememberPhoneNumber: eventObj.target.checked
    });
  };

  render() {
    const { classes } = this.props;
    const placeholder = this.props.intl.formatMessage(getIntl("phone"));
    const enableFlagSelection = window.CtxAppConfigurations.enableFlagSelection;

    let buttonMessage;
    if (this.props.session.callState === "Connecting") {
      buttonMessage = this.props.intl.formatMessage(getIntl("cancel"));
    } else {
      buttonMessage = this.props.intl.formatMessage(getIntl("connect"));
    }

    let errorMessage;
    if (this.state.error) {
      if (this.state.duplicatePhoneNumber) {
        errorMessage = this.props.intl.formatMessage(getIntl("duplicateParty"));
      } else if (
        this.state.phoneNumber == null ||
        this.state.phoneNumber === ""
      ) {
        errorMessage = this.props.intl.formatMessage(getIntl("emptyPhone"));
      } else {
        errorMessage = this.props.intl.formatMessage(getIntl("invalidPhone"));
      }
    }

    return (
      <Fragment>
        <LocalizedText className={classes.root} value="callMe" variant="h6" />
        <div
          className={classNames(classes.telephoneInput, {
            [classes.telephoneInputLarge]: !isCM() && enableFlagSelection,
            [classes.telephoneInputSmall]: !isCM() && !enableFlagSelection,
            [classes.telephoneInputMedium]: isCM()
          })}
          ref={elem => (this.phoneNumberDivRef = elem)}
        >
          {isCM() ? (
            <CTXTextField
              label={placeholder}
              value={this.state.phoneNumber}
              onChange={this.handleCMPhoneNumberChange}
              onBlur={this.handlePhoneNumberBlur}
              onKeyDown={this.handleKeyPress}
              error={this.state.error}
              fullWidth
              className={classes.cmTelephoneInput}
            />
          ) : (
            <IntlTelInput
              allowDropdown={enableFlagSelection}
              onPhoneNumberChange={this.handlePhoneNumberChange}
              onPhoneNumberBlur={this.handlePhoneNumberBlur}
              onSelectFlag={this.handleSelectFlag}
              placeholder={placeholder}
              containerClassName={classNames(
                classes.intlTelInput,
                "intl-tel-input"
              )}
              inputClassName={classNames(
                { [classes.error]: this.state.error },
                classes.input
              )}
              format
              ref={this.intlTelInputRef}
              fieldName="phone"
              preferredCountries={window.CtxAppConfigurations.preferredCountryCodes
                .split(",")
                .map(item => item.trim())
                .filter(item => item !== "")}
            />
          )}
          {this.state.error && (
            <Typography className={classes.root} color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}
        </div>
        {this.props.session.callState === "Connecting" ? (
          <LocalizedText
            className={classes.connectingMessage}
            value="connectingDotDotDot"
          />
        ) : (
          <Fragment>
            <div className={classes.rememberPhoneNumberContainer}>
              {this.renderRememberPhoneNumber()}
            </div>
            {!enableFlagSelection && !isCM() && (
              <LocalizedText
                className={classes.flagMessage}
                value="onlyUSNumbersAllowed"
              />
            )}
          </Fragment>
        )}

        <TextButton
          color={
            this.props.session.callState === "Connecting" ? "error" : undefined
          }
          onClick={this.handleButtonClick}
        >
          <Typography color="inherit" variant="body2">
            {buttonMessage}
          </Typography>
        </TextButton>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, participants }) => ({
  session,
  participants
});

const mapDispatchToProps = dispatch => ({
  call: (party, userId) =>
    dispatch(createAndCallParty(party, userId)).then(party => {
      logger.debug(
        'Created and called the new party through the REST call. "%s"',
        party
      );
    }),
  updateCallState: callState => dispatch(updateCallState(callState)),
  disconnectAndRemoveParty: (partyids, userid) =>
    dispatch(disconnectAndRemoveParty(partyids, userid))
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(CallMe))
);
